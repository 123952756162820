<template>
  <div>
    <title>SIM INVENTORY ~ ADD PRODUCT DATA</title>
    <section class="content-header">
      <h1>
        Add Product Data
        <br />
        <h4>Please enter product transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Product Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-save"></i>
              <h3 class="box-title">Save Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    v-model="isidata.produkno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Name</label>
                  <input
                    type="text"
                    v-model="isidata.produkname"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Name"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Size</label>
                  <input
                    type="text"
                    v-model="isidata.ukuran"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Size"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Color</label>
                  <input
                    type="text"
                    v-model="isidata.warna"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Color"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">SKU</label>
                  <input
                    type="text"
                    v-model="isidata.skuf"
                    autocomplete="off"
                    class="form-control"
                    placeholder="SKU"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Copyright</label>
                  <input
                    type="text"
                    v-model="isidata.haki"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Copyright"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatan"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-save"></i>
              <h3 class="box-title">Save Detailed Product Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Part Code</label>
                    <input
                      type="text"
                      v-model="isidata.kodepart"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Part Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getpart()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Part Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.namapart"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="isidata.qtyd"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.catatand"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/produk">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
     
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      isidata: {
        produkno: "",
        produkname: "",
        ukuran: "",
        warna: "",
        skuf: "",
        haki: "",
        catatan: "",
        kodepart: "",
        namapart: "",
        qtyd: "",
        catatand: ""
      }
    };
  },
  methods: {
    async getpart() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/part_component/getpart_componentbypart_no?part_no=" + this.isidata.partnod;
      const urlAPIget =
        this.$apiurl +
        "part_component/getpart_componentbypart_no?part_no=" +
        this.isidata.kodepart;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Part Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.namapart = resp.data.data.name;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async saveData() {
      this.loading = true;
      var nop = this.isidata.produkno;
      var namap = this.isidata.produkname;
      var ukuranp = this.isidata.ukuran;
      var warnap = this.isidata.warna;
      var skufp = this.isidata.skuf;
      var hakip = this.isidata.haki;
      var catatanp = this.isidata.catatan;
      var kodepart = this.isidata.kodepart;
      var namapart = this.isidata.namapart;
      var qtydet = this.isidata.qtyd;
      var catatandet = this.isidata.catatand;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (namap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ukuranp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Size can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (warnap == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Color can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (skufp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "SKU can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodepart == "" || namapart == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Part Name / Part Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtydet == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatandet == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          nomor_produk: nop,
          nama_produk: namap,
          size: ukuranp,
          color: warnap,
          sku: skufp,
          copyright: hakip,
          notes: catatanp,
          kode_user: kodeuser
        };

        var paramdatadetail = {
          nomor_produk: nop,
          part_no: kodepart,
          qty: qtydet,
          notes: catatandet,
          kode_user: kodeuser,
          part_name: namapart,
        };

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/saveproduk_h";
        var urlAPIsavedata = this.$apiurl + "produk_h/saveproduk_h";
        var urlAPIsavedatadet = this.$apiurl + "produk_d/saveproduk_d";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              axios
                .post(urlAPIsavedatadet, paramdatadetail, { headers })
                .then((resp) => {
                  if (resp.data.status == true) {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Save data successfully",
                      showConfirmButton: false
                    });
                    this.$router.push({ name: "produkheader" });
                  } else {
                    swal({
                      icon: "warning",
                      title: "Warning",
                      text: "Save data failed",
                      showConfirmButton: false
                    });
                    this.loading = false;
                    this.$router.push({ name: "produkheader" });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "produkheader" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
